.c-header {
	position: fixed;
	top: 0;
	z-index: 999;
	width: 100%;
	background: #fff;
	padding: 0 8%;

	&__logo {
		max-width: 235px;
		padding: 20px 0;
		transition: all 0.5s ease;

		@include media('>900px') {
			max-width: 270px;
			float: left;
		}

		@include media('>930px') {
			max-width: 300px;
		}

	}

	&__nav {

		@include media('>890px') {
			float: left;
			padding-top: 30px;
		}

		&-toggle {
			position: absolute;
			top: 30px;
			left: 10%;
			z-index: 999;
			cursor: pointer;
			height: 30px;
			width: 40px;
			
			@include media('>900px') {
				display: none;
			}
			
			.c-navicon .c-navicon__line {
				width: 100%;
				height: 5px;
				display: block;
				border-bottom: 4px solid #000;
				margin-bottom: 5px;
			}
		}
	}

	&--sticky {
		.c-header__logo {
			max-width: 235px;
			padding: 5px 0;
		}
		.c-header__nav {
			padding-top: 0;
		}
		.c-header__nav-toggle {
			top: 15px;
		}
	}

}

.social-icons {
	float: right;
	margin-top: 20px;
	padding-bottom: 15px;
	
	img {
		width: 45px;
		margin-right: 6px;
		
		@include media('<350px') {
			width: 30px;
		}
	}
	
	@include media('>900px') {
		margin-top: 30px;
	}
			
}
