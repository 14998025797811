/**
 * Default Styles for html and body Element
 *
 * @package  generator-mh-boilerplate
 * @author   Martin Herweg <info@martinherweg.de>
 * @author   David Hellmann <davidhellmann.com@gmail.com>
 */

/*
|--------------------------------------------------------------------------
| _elements.page.scss
|--------------------------------------------------------------------------
*/


html {
	overflow-y: scroll;
	height: 100%;

	font-family: $base-font-family;
	font-size: $base-font-size;
	line-height: $base-line-height;
	font-weight: $base-font-weight;
	color: $color-text;
	background: $color-bg;
	hyphens: auto;

	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: $color-primary !important;

	// Add ligatures
	//font-feature-settings: "liga","dlig";

	// Two ways to smoothen type, they are disabled by default
	// -webkit-font-smoothing: antialiased;
	//    -moz-font-smoothing: antialiased;
	// font-smooth:always;
}

body {
	height: 100%;
}


.container {
	margin-top: 70px;
	text-align: center;
	width: 66.67%;
	margin: 70px auto;
}

.container-datenschutz {
	margin-top: 30px;
}

.container-datenschutz-seite {
	text-align: center;
    width: 66.67%;
    margin: 150px auto 70px auto;
}

.container-logo {
	text-align: center;
	margin-top: 50px;
}

.container-grau {
	background-color: color('grey', 'base');
	text-align: center;
	padding: 70px 70px;
}

.instawidget {
	margin: 0 auto;
	width: 66.67%;
}

/*.eapps-instagram-feed-posts-grid-load-more {
	margin-top: 48px !important;
	border-radius: 0px !important;
	font-family: $base-font-family;
	font-size: 16px !important;
	text-transform: uppercase;
	padding: 0 30px 0 30px;
	background-size: 8px !important;
	height: 45px !important;
	margin-top: 20px !important;
	transition: 0.3s ease !important;
	line-height: 45px !important;

	&:hover {
		cursor: pointer !important;
		padding: 0 35px 0 35px !important;
	} 
}*/

.responsiveContainer {
  position: relative;
  padding-bottom: 38%;
  height: 0;
  overflow: hidden;
}

.responsiveContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
