form {
	max-width: 1000px;
	margin: auto;
}
input {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	padding: 10px 20px;
	border: 1px solid #969696;
}
select {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;

	position: relative;
	appearance: none;
	border: 1px solid #969696;
	border-radius: 0;
	padding: 10px 20px;
	cursor: pointer;
	background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNSIgaGVpZ2h0PSI5IiB2aWV3Qm94PSIwIDAgMTUgOSI+Cgk8cGF0aCBmaWxsPSIjMzMzMzMzIiBkPSJNNy44NjcgOC41NzRsLTcuMjItNy4yMi43MDctLjcwOEw3Ljg2NyA3LjE2IDE0LjA1Ljk4bC43MDYuNzA3Ii8+Cjwvc3ZnPgo=") right 10px center no-repeat;
}
textarea {
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	color: inherit;
	padding: 10px 20px;
	border: 1px solid #969696;
}
input[type="submit"] {
	border: 1px solid $color-primary;
	background: $color-primary;
	color: #fff;
	cursor: pointer;

	&:hover {
		border: 1px solid $color-text;
		background: $color-text;
	}
}


// overwrite ios style
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
.input-checkbox {
	-webkit-appearance: none;
	border-radius: 0;
}

input[type="file"] {
	width: 100%;
}
