.booking-form {
	max-width: 1100px;
	width: 100%;
	margin: 0 auto;
	//@extend %clearfix;
	
	@include media('>600px') { 
		width: 66.67%;
	}

	fieldset {
		position: relative;
		margin: 0 0 15px;
		border: none;
		padding: 0;
	}

	input[type="text"],
	input[type="email"],
	input[type="tel"],
	textarea,
	select {
		width: 100%;
		font-size: 16px;
		font-weight: 300;
		color: #fff;
		background-color: color('grey', 'dark');
		padding: 17px 15px;
		border: none;
		cursor: pointer;
	}
	
	input[type="text"].strasse {
		width: 48%;
		float: left;
	}
	
	input[type="text"].ort {
		width: 48%;
		float: right;
	}
		
	textarea {
		resize: none;
		height: 119px;
	}

	select {
		-webkit-appearance: none;
		-moz-appearance: none;

		height: 51px;
		border-radius: 0;
	}

	input[type="submit"] {
		border: none;
		font-family: $base-font-family;
		text-transform: uppercase;
		padding: 0 30px 0 30px;
		color: #fff;
		background: color('primary');
		background-size: 8px;
		height: 45px;
		margin-top: 20px;
		transition: 0.3s ease;

		&:hover {
			cursor: pointer;
			padding: 0 35px 0 35px;
		}
	}

	::-webkit-input-placeholder {
		color: color('grey');
	}
	::-moz-placeholder {
		color: color('grey');
	}
	:-ms-input-placeholder {
		color: color('grey');
	}
	:-moz-placeholder {
		color: color('grey');
	}

	label.error {
		position: absolute;
		top: 0;
		right: 5px;
		color: #ea0101;
	}
	label#datenschutz-error {
		top: 14px !important;
	}
}

#rc-imageselect, .g-recaptcha {
	
	@include media('<445px') { 
		transform:scale(0.77);
		transform-origin:0 0;
	}
}