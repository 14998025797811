.c-navigation {
	@extend %list-reset;
	display: none;


	@include media('<640px') {
		position: absolute;
		width: 90%;
		background: #fff;
		z-index: 900;
	}

	@include media('>900px') {
		display: inline-block;
		background: none;
	}

	&__item {
		position: relative;
		text-align: center;

		@include media('>900px') {
			display: inline-block;
			text-align: left;
			margin-right: 70px;
			
			&:last-child {
				margin-right: 0;
			}
		}
	}

	&__item-level1 {
		display: block;
		padding: 18px 0;
		position: relative;
		text-transform: uppercase;
		font-size: $type-mid;

		@include media('>900px') {
			margin-left: 0;
		}

		&:link, &:visited {
			color: $color-text;
		}

		&:hover, &.hover,
		&--active:link, &--active:visited {
			text-decoration: underline;
		}

	}

}
