.u-iframe {
	&__embed {
		margin-top: 55px;
		position: relative;
		padding-bottom: 56.25%; /* ratio 16x9 */
		height: 0;
		overflow: hidden;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&--ratio4x3 {
			padding-bottom: 75%;
		}
	}

}
