.o-grid {
	@include media('>grid') {
		display: flex;
	}
	
	&-newrow {
	@include media('>grid') {
		display: flex;
	}
		
		margin-top: 30px;
	}

	&__item50 {
		flex: 1 1;
		display:flex;
		flex-direction: column;
		margin-right: gutter();
		margin-left: gutter();
	}

	&__item30 {
		flex: 1 1 span(4);
		margin-right: gutter();
		margin-bottom: 40px;
		
		
		@include media('>grid') { 
			margin-bottom: 0;
		}
	}
	
	&__item70 {
		flex: 1 1 span(8);
		text-align: left;
		margin-right: 40px;
	}

	&__item25 {
		flex: 1 1 span(3);
		margin-right: gutter();

		&:last-child {
			margin-right: 0;
		}
	}

}

.spalte1 {
	clear: both;
	width: 47%;
	float: left;
	margin-top: 20px;
	
	@include media('<818px') {
		width: 100%;
	}
}

.spalte2 {
	width: 47%;
	float: right;
	margin-top: 20px;
	
	h4 {
		float: right;
		font-size: $type-large;	
		font-family: $font-family-sans;
		margin-bottom: 24px;
	}
	
	p {
		clear: both;
	}
	
	@include media('<818px') {
		width: 100%;
		float: none;
	}
}

.eineSpalte {
	float: left;
	margin-top: 20px;
}



