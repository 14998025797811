/*	--------------------------------------------------
	Reveal Modals
	-------------------------------------------------- */
		
	.reveal-modal-bg { 
		position: fixed; 
		height: 100%;
		width: 100%;
		background: #000;
		background: rgba(0,0,0,.8);
		z-index: 100;
		display: none;
		top: 0;
		left: 0; 
		}
	
	.reveal-modal {
		//visibility: hidden;
		display: none;
		top: 100px; 
		left: 50%;
		margin-left: -40%;
		width: 100%;
		max-width: 80%;
		background: #efefef;
		position: absolute;
		z-index: 101;
		padding: 45px 40px 45px;
		//border-radius: 10px;
		box-shadow: 0 0 2px 1px #d7d7d7;
		
			h2 {
				float: left;
				margin-top: 40px;
				margin-bottom: 40px;
			}
			
			h4 {
				float: right;
				font-size: $type-large;
				font-family: $base-font-family;
				margin-top: 40px;
			}
			
		
			h3 {
				clear: both;
			}	
			
			ul {
				list-style-type: square;
			}
			
			@include media('>1180px') {
				max-width: 950px;
				margin-left: -475px;

				}
				
			@include media('<625px') {
				h4 {
					margin-top: 0;
				}
			}
			
		}
		
	.reveal-modal img {
		width: 100%;
		max-height: 350px;
	}
	
	.images {
		text-align: center;
		
		@include media('>859px') {
			img {
				&:last-child {
					margin-right: 0;
				}
			}
		}
		
		@include media('<860px') {
			img {
				&:last-child {
					margin-top: 20px;
					
				}
			}
		}
		
		@include media('<605px') {
			img {
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
					margin-top: 0;
				}				
			}
		}
		
		
	}
	
	.anfragenButton {
		height: 45px;
		width: 145px;
		background-color: #000;
		color: #fff;
		font-size: $type-mid;
		text-transform: uppercase;
		float: right;
		
		@include media('<772px') {
			margin-top: 20px;
		}
	}
		
	.reveal-modal.small 		{ width: 200px; margin-left: -140px;}
	.reveal-modal.medium 		{ width: 400px; margin-left: -240px;}
	.reveal-modal.large 		{ width: 600px; margin-left: -340px;}
	.reveal-modal.xlarge 		{ width: 800px; margin-left: -440px;}
	
	.reveal-modal .close-reveal-modal {
		font-size: 45px;
		line-height: .5;
		position: absolute;
		top: 13px;
		right: 13px;
		color: #aaa;
		text-shadow: 0 -1px 1px rbga(0,0,0,.6);
		font-weight: bold;
		cursor: pointer;
		} 
	/*
		
	NOTES
	
	Close button entity is &#215;
	
	Example markup
	
	<div id="myModal" class="reveal-modal">
		<h2>Awesome. I have it.</h2>
		<p class="lead">Your couch.  I it's mine.</p>
		<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ultrices aliquet placerat. Duis pulvinar orci et nisi euismod vitae tempus lorem consectetur. Duis at magna quis turpis mattis venenatis eget id diam. </p>
		<a class="close-reveal-modal">&#215;</a>
	</div>
	
	*/