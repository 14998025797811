/* === Images === */
img {
	width: 100%;
	height: auto;
	vertical-align: middle;
}

img.emotion {
	margin-top: 60px;
}

img.logo {
	max-width: 50%;
	
	@include media('<900px') {
		max-width: 80%;
	}
}

img.product {
	max-width: 93%;
}

img.emile {
	max-width: 350px;
	
	@include media('<500px') {
			max-width: none;
			width: 100%;
		}
}

