.booking {
	position: relative;
	text-align: center;

	/*&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		border: 15px solid;
		border-top-width: 30px;
		border-bottom-color: transparent;
		border-left-width: 30px;
		border-right-width: 30px;
	}*/

	.container {
		//@extend %clearfix;

		.col6 {
			@include media('>desktop') {
				width: 50%;
				margin: 0;
				padding: 0 25px;
			}
		}
	}

	h5 {
		color: color('grey');
		max-width: 500px;
		padding: 0 50px;
		margin: 0 auto 50px;

		@include media('>tablet') {
			padding: 0;
		}
	}

	.thanks {
		font-size: 16px;
		margin: 0;
	}
	p { color: #000;}
}