.c-footer {
		background-color: color('grey', 'base');
		padding: 28px 0;
		text-align: center;
		
		ul {
			margin: 0;
			padding: 0;

			li {
				list-style: none;
				font-size: $base-font-size;

				@include media('>900px') {
					display: inline-block;
					padding-left: 70px;

					&:first-child {
						padding-left: 0;
					}
				}
			}

			a {
				color: #000000;

				&:hover {
					color: #ccc;
				}			
			}
		}
}

