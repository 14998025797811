/**
 * Page-level constraining and wrapping elements.
 *
 * @package  generator-mh-boilerplate
 * @author   Martin Herweg <info@martinherweg.de>
 * @author   David Hellmann <davidhellmann.com@gmail.com>
 * @author   Harry Roberts <https://github.com/inuitcss/inuitcss>
 */

/*
|--------------------------------------------------------------------------
| _objects.wrapper.scss
|--------------------------------------------------------------------------
*/


.o-wrapper {
	@include gutter(1);

	max-width: $app-max-width;
	margin-right: auto;
	margin-left: auto;
}
