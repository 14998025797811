//------------------------------------//
//  $VARIABLES
//------------------------------------//


// Fonts
@font-face { font-family: 'corpo-demi';
             src: url('../fonts/CorporateS-Demi.otf') format('opentype'); }
             
@font-face { font-family: 'corpo-regular';
             src: url('../fonts/CorporateS-Regular.otf') format('opentype'); }

$font-family-sans             	: "corpo-regular", Helvetica, Arial, sans-serif;
$font-family-sans-demi          : "corpo-demi", Helvetica, Arial, sans-serif;


// Base
$base-font-size					: 16px;
$base-line-height				: 24px;
$base-font-size--mobile			: $base-font-size;
$base-font-weight				: 400;
$base-font-weight-bold			: bold;

$base-font-family             	: $font-family-sans;
$base-font-family-alt         	: $font-family-sans-demi;

$font-family-headings         	: $base-font-family-alt; // using inherit will use $font-family-base for all headings
$font-weight-headings         	: 300;    // instead of browser default, bold
$line-height-headings         	: 1.1;

$type-largest					: 35px;
$type-large						: 30px;
$type-mid						: 20px;
$type-base						: $base-font-size;
$type-small						: 12px;


// Calculated measurements
$line-height-ratio: $base-line-height / $base-font-size;
$base-spacing-unit: $line-height-ratio;
$half-spacing-unit: $base-spacing-unit / 2;
